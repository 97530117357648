<template>
    <div class="black sticky top-[0]">
        <div class="header py-[15rem] inner">
            <div class="header-left flex items-center">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>
                <nav>
                    <ul class="flex items-center hidden-sm-and-down">
                        <li class="active">{{ $t("nav.Stats") }}</li>
                        <li style="margin-left: 80rem">{{ $t("nav.APIKeys") }}</li>
                    </ul>
                </nav>
            </div>
            <div class="header-right flex items-center hidden-sm-and-down">
                <div class="language flex items-center">
                    <el-icon>
                        <QuestionFilled />
                    </el-icon>
                    <ul class="flex items-center">
                        <!--active-->
                        <li>{{ $t("nav.Ticket") }}</li>
                    </ul>
                </div>
                <div>
                    <el-dropdown :hide-on-click="false" popper-class="statsDropDown">
                        <span class="el-dropdown-link">
                            {{ $t("nav.Settings") }}
                            <el-icon class="el-icon--right">
                                <el-icon>
                                    <ArrowDownBold />
                                </el-icon>
                            </el-icon>
                        </span>
                        <template #dropdown>
                            <div class="statsDropDown">
                                <el-dropdown-menu>
                                    <el-dropdown-item>Action 1</el-dropdown-item>
                                    <el-dropdown-item>Action 2</el-dropdown-item>
                                </el-dropdown-menu>
                            </div>

                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.statsDropDown ::v-deep .el-dropdown-menu {
    background: #383838;
    color: white;
}

/* Desktop layout */
.black {
    background: black;
    color: white;

    ul {
        color: white;

    }
}

.header {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.header-left {
    .logo {
        width: 38rem;
        height: 50rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    nav {
        margin-left: 100rem;
        font-size: 18rem;
        font-family: Inter, serif;
        font-weight: normal;
        color: #000000;
        line-height: 18rem;

        li {
            cursor: pointer;

            &.active {
                border-bottom: 4rem solid #435DC8ed;
                padding: 6rem 0;
            }
        }
    }
}

.header-right {
    margin-left: auto;

    .language {
        margin-right: 50rem;
        font-size: 18rem;

        li {
            cursor: pointer;
            //border-right: 1rem solid #ccc;

            padding: 0 5rem;

            &::after {
                content: ' \/'
            }

            &:last-child::after {
                content: '';
            }

            &:hover {
                color: #7188de;
            }

            &.active {
                color: #435DC8;
            }
        }
    }

    .btn {
        width: 180rem;
        height: 40rem;
        line-height: 40rem;
        color: white;
        font-size: 18rem;
        background: #435DC8;
        text-align: center;
        cursor: pointer;
    }
}

.el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-white);
    display: flex;
    font-size: 18rem;
    align-items: center;

    &:focus {
        outline: none;
    }
}
</style>
