export default {
    nav:{
        home: 'Home',
        service:'Service',
        faas: 'FaaS',
        contact:'Contact',
        getStart:'Get started',
        Stats:'Stats',
        APIKeys:'API Keys',
        Ticket:'Ticket',
        Account:'Account',
        Billing:'Billing',
        LogOut:'Log Out',
        Settings:'Settings',
        fractionalizeAssets: 'Fractionalize Your Assets',
        dappDevelopment: 'DApp Development',
        product: 'Product',
        rwaplatform: 'RWA Platform',
        dapps: 'DApps',
        otherProducts: 'Other Blockchain Products',
        live: 'Live'
    },
    common: {
        siteTitle: 'HashMelon - Tokenize your assets',
    },
    login:{
        welcome:'Welcome',
        welcomeDesc:'Login to hashmelon',
        Email:'Email',
        EmailAddress:'Email Address',
        Password:'Password',
        ForgotPassword:'Forgot Password？',
        SignIn:'Sign in',
        ResetPassword:'Reset your password',
        Next:'Next',
        Cancel:'Cancel',
        VerificationCode:'Verification code',
        EnterCode:'Enter Code',
        Resend:'Resend',
        NewPassword:'New Password',
        RepeatPassword:'Repeat Password',
        newPassword:'Repeat new Password',
        ChangePassword:'Change Password'
    },
    home:{
        welcome:'Welcome to HashMelon',
        welcomeStr:'HashMelon is a company dedicated to providing FaaS (Fractionalization as a Service) services.',
        welcomeBtn:'Lean more',
        getStart:'Get started',
        getStartStr:'HashMelon is the most efficient way to fragment all your assets.\n' +
            'This technology is innovative and constantly evolving.\n' +
            'Contacting us can assist you greatly.\n' +
            'If you have the need to fragment physical assets, here are our recommendations for you.',
        cardOneLeftTile:'Choose your business type',
        cardOneLeftStr:'We currently support fragmented services for real estate, yachts, antiques, and more. Please look forward to our expanding service support in other fields.',
        cardOneRightTile:'Contact the team',
        cardOneRightStr:'Reach out to us for more consultation, including how to operate, technical integration, compliance risk control, and other issues. We will provide answers from a professional perspective.',
        cardTwoLeftTile:'Choose your package',
        cardTwoLeftStr:'Based on our professional feedback and combined with the actual situation, select the service package that best suits your needs. This includes fragmentalization services, mint services, secondary market services, and more.',
        cardTwoRightTile:'Begin construction',
        cardTwoRightStr:'After purchasing the service, you can start your journey of fragmentation services using our management console.',
        whatIsFaaS:'What is FaaS?',
        whatIsFaaSStr:'FaaS is a technology that can fragment real assets into NFTs, thereby transferring tangible assets into the Web3 world for a one-to-one correspondence, where each fragment in reality corresponds to a unique NFT on the blockchain. Our underlying interfaces are very rich, catering to users\' bold new ways of making money, as well as many other innovative functions. FaaS services are open to all enterprises, regardless of your industry, we will meet all your needs for asset fragmentation support.',
        whatIsFaaSBtn:'Contact us',
        managementConsoleTitle:'Convenient and user-friendly Management console',
        managementConsoleStr:'We provide a corporate account, allowing users to log into our enterprise management console, which has powerful features. Even teams without Web3 technology knowledge can easily and conveniently fragment their existing assets, achieving a one-stop service.',
        web3Title:'Web3 Technology Support',
        web3Str:'We have overcome numerous technical barriers to align our products with NFTs on the Web3 network, ensuring all information is transparent, traceable, and immutable. This safeguards the rights and interests of users in the real world.',
        PRICING:'BEST PRICING',
        plan1:'No hidden charges!',
        plan2:'choose your plan.',
        FreeTrial:'Free trial',
        Save:'Save',
        planBtn:'Purchase Plan',
        Monthly:'Monthly',
        DemoFile:'Demo file',
        Dedicated:'10 GB Dedicated Hosting free',
        Support:'Lifetime free Support',
        Optimiized:'SEO Optimiized',
        LiveSupport:'Live Support',
        comingSoon: 'Coming Soon...',
        footerStr:'Follow us on social media for upcoming announcements.',
        footerLink1:'Team',
        footerLink2:'FAQ',
        footerLink3:'About',
        footerLink4:'Tokenize Your House',
        footerLink5:'Contact',
    },
    faas: {
        title: 'FaaS - Fractionalization as a Service',
        description: 'Fractionalization as a Service (FaaS) is a compliant, open, and user-friendly platform for bringing real assets onto the blockchain. It helps asset owners tokenize part or all of their property rights through retail methods, increasing liquidity and lowering transaction barriers for quick capital recovery.',
        tokenModelTitle: 'Token Model',
        tokenModelDesc: 'FaaS uses the Semi-Fungible Token Protocol (ERC3525) to provide unprecedented flexibility and scalability for asset tokenization while ensuring compliance and security.',
        tokenModelComparison: 'Token Model Comparison',
        tokenType: 'Token Type',
        homogeneous: 'Fungible Token',
        nonHomogeneous: 'Non-Fungible Token',
        semiHomogeneous: 'Semi-Fungible Token',
        uniqueness: 'Uniqueness',
        content: 'Content',
        value: 'Value',
        valueAndMore: 'Value + Image + Others',
        uniquenessSupport: 'Uniqueness Support',
        liquidity: 'Liquidity',
        metadata: 'Metadata',
        shareSplit: 'Share Split',
        medium: 'Medium',
        poor: 'Poor',
        useCasesTitle: 'Use Cases',
        useCasesDesc: 'FaaS is applicable to multiple fields such as real estate fractionalized investment and agricultural product supply chain management, helping traditional industries transform digitally.',
        propertyName: 'Property Name',
        propertyLocation: 'Location',
        losAngeles: 'Los Angeles, USA',
        propertyArea: 'Area',
        propertyValue: 'Value',
        expectedReturn: 'Expected Annual Return',
        tokenAmount: 'Token Amount',
        minInvestment: 'Minimum Investment',
        serviceSupportTitle: 'Service Support',
        serviceSupportDesc: 'FaaS provides multiple versions of service support, from basic to professional, tailored for clients with different needs.',
        source: 'Source'
    },
    rwa: {
        title: 'RWA Platform',
        description: 'Explore our Real World Asset platform ecosystem, offering innovative digital investment opportunities.',
    }
}
