export default {
    nav: {
        home: '首页',
        service: '服务',
        faas: 'FaaS',
        rwaPlatform: 'RWA 平台',
        contact: '联系我们',
        getStart: '开始',
        Stats: '统计',
        APIKeys: 'API密钥',
        Ticket: '工单',
        Account: '账户',
        Billing: '账单',
        LogOut: '退出',
        Settings: '设置',
        fractionalizeAssets: '份额化你的资产',
        dappDevelopment: 'DApp开发',
        product: '产品',
        rwaplatform: 'RWA平台',
        dapps: 'DApps',
        otherProducts: '其他区块链产品',
        live: '演示'
    },
    common: {
        siteTitle: 'HashMelon - 资产通证化',
    },
    login:{
        welcome:'欢迎',
        welcomeDesc:'登录到HashMelon',
        Email:'邮箱',
        EmailAddress:'请输入邮箱',
        Password:'密码',
        ForgotPassword:'忘记密码？',
        SignIn:'登录',
        ResetPassword:'重置您的密码',
        Next:'下一步',
        Cancel:'取消',
        VerificationCode:'验证码',
        EnterCode:'请输入验证码',
        Resend:'发送',
        NewPassword:'新的密码',
        RepeatPassword:'再次输入新的密码',
        ChangePassword:'确认'
    },
    home: {
        welcome: '欢迎来到HashMelon',
        welcomeStr: 'HashMelon是一家致力于提供FaaS（资产份额化服务）的公司',
        welcomeBtn: '了解更多',
        getStart: '开始使用',
        getStartStr: `HashMelon是资产份额化的最佳解决方案。
                     这项技术持续创新发展。
                     与我们联系可以为您提供专业帮助。
                     如果您需要对实物资产进行份额化，以下是我们为您提供的建议。`,
        cardOneLeftTile: '选择业务类型',
        cardOneLeftStr: '我们目前支持房地产、游艇、古董等资产的份额化服务。更多领域的服务支持敬请期待。',
        cardOneRightTile: '联系团队',
        cardOneRightStr: '欢迎咨询包括运营方式、技术对接、合规风控等问题，我们将从专业角度为您解答。',
        cardTwoLeftTile: '选择套餐',
        cardTwoLeftStr: '根据我们的专业反馈，结合实际情况，选择最适合您需求的服务套餐。包括份额化服务、铸造服务、二级市场服务等。',
        cardTwoRightTile: '开始构建',
        cardTwoRightStr: '购买服务后，您可以使用我们的管理控制台开启资产份额化之旅。',
        whatIsFaaS: '什么是FaaS？',
        whatIsFaaSStr: 'FaaS是一项可以将实物资产份额化为NFT的技术，通过这种方式将有形资产一比一对应转移到Web3世界，现实中的每个份额都对应区块链上独一无二的NFT。我们的底层接口非常丰富，既满足用户大胆创新的赚钱方式，也包含许多其他创新功能。FaaS服务面向所有企业开放，无论您处于哪个行业，我们都将满足您对资产份额化的全方位支持。',
        whatIsFaaSBtn: '联系我们',
        managementConsoleTitle: '便捷易用的管理控制台',
        managementConsoleStr: '我们提供企业账户，用户可登录我们功能强大的企业管理控制台。即使是没有Web3技术知识的团队，也能轻松便捷地对现有资产进行份额化，实现一站式服务。',
        web3Title: 'Web3技术支持',
        web3Str: '我们攻克了众多技术壁垒，使产品与Web3网络上的NFT完美对接，确保所有信息透明、可追溯且不可篡改。这为用户在现实世界中的权益提供了保障。',
        PRICING: '最佳定价',
        plan1: '没有隐藏的费用',
        plan2: '选择您的计划。',
        FreeTrial: '免费试用',
        Save: '节省',
        planBtn:'购买计划',
        Monthly: '月',
        DemoFile: '演示文件',
        Dedicated: '10 GB专用托管免费',
        Support: '终身免费支持',
        Optimiized: 'SEO优化',
        LiveSupport: '在线支持',
        comingSoon: '即将推出...',
        footerStr: '在社交媒体上关注我们即将发布的公告。',
        footerLink1: '团队',
        footerLink2: '常见问题',
        footerLink3: '关于',
        footerLink4: '象征你的房子',
        footerLink5: '联系我们',
    },
    faas: {
        title: 'FaaS - 碎片化即服务',
        description: '碎片化即服务（Fractionalization as a Service，即FaaS）是Hashmelon提供的合规、开放、易用的现实资产上链的平台。它帮助资产所有者通过零售方式将部分或全部产权份额化，增加流通性，降低交易门槛，从而快速回笼资金。',
        tokenModelTitle: '代币模型',
        tokenModelDesc: 'FaaS通过半同质化代币协议（ERC3525），为资产代币化提供前所未有的灵活性与扩展性，同时确保合规性与安全性。',
        tokenModelComparison: '代币模型对比',
        tokenType: '代币类型',
        homogeneous: '同质化代币',
        nonHomogeneous: '非同质化代币',
        semiHomogeneous: '半同质化代币',
        uniqueness: '独特性',
        content: '内容',
        value: '价值',
        valueAndMore: '价值+图片+其他',
        uniquenessSupport: '独特性支持',
        liquidity: '流动性',
        metadata: '元数据',
        shareSplit: '股份分割',
        medium: '一般',
        poor: '差',
        useCasesTitle: '应用场景',
        useCasesDesc: 'FaaS适用于房地产碎片化投资、农产品产业链管理等多个领域，助力传统行业向数字化转型。',
        propertyName: '房屋名称',
        propertyLocation: '房屋位置',
        losAngeles: '美国洛杉矶',
        propertyArea: '房屋面积',
        propertyValue: '房产价值',
        expectedReturn: '预期年化收益',
        tokenAmount: '发行代币数量',
        minInvestment: '最低投资金额',
        serviceSupportTitle: '服务支持',
        serviceSupportDesc: 'FaaS提供多个版本的服务支持，从基础版到专业版，为不同需求的客户量身打造。',
        source: '来源'
    },
    rwa: {
        title: 'RWA 平台',
        description: '探索我们的实物资产平台生态系统，为您提供创新的数字化投资机会。',
    },
}
