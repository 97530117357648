<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const isMobile = ref(false)

// 监听窗口大小变化
const handleResize = () => {
    isMobile.value = window.innerWidth <= 768
}

// 初始化和添加事件监听
handleResize()
window.addEventListener('resize', handleResize)

const iconList = ref([require('@/assets/img/footer/medium.png'),
require('@/assets/img/footer/twitter.png'),
require('@/assets/img/footer/telegram.png'),
require('@/assets/img/footer/discord.png'),
require('@/assets/img/footer/Youtube.png'),
require('@/assets/img/footer/linkedin.png')])

// 计算样式
const logoStyle = computed(() => ({
    height: isMobile.value ? '120rem' : '200rem',
    marginTop: isMobile.value ? '50rem' : '100rem'
}))

const descStyle = computed(() => ({
    padding: isMobile.value ? '0 20rem' : '0',
    textAlign: 'center'
}))
</script>

<template>
    <footer class="footer" :class="{ mobile: isMobile }">
        <div class="flex items-center justify-center flex-col footer-box">
            <div class="logo mb-[50rem]">
                <img src="@/assets/img/hashmelon-with.png" :style="logoStyle" alt="">
            </div>
            <div class="desc" :style="descStyle">{{ $t("home.footerStr") }}</div>
            <div class="iconList flex m-[50rem]" :class="{ 'flex-wrap justify-center': isMobile }">
                <img :src="item" class="mx-[10rem]" :class="{ 'my-[10rem]': isMobile }" alt="" v-for="item in iconList" :key="item">
            </div>
            <ul class="flex items-center" :class="{ 'flex-col w-full': isMobile, 'justify-between': !isMobile }">
                <li class="text-center">{{ $t("home.footerLink1") }}</li>
                <li class="text-center">{{ $t("home.footerLink2") }}</li>
                <li class="text-center">{{ $t("home.footerLink3") }}</li>
                <li class="text-center">{{ $t("home.footerLink4") }}</li>
                <li class="text-center"><a href="mailto:info@hashmelon.com" class="hover:opacity-80 transition-opacity">{{ $t("home.footerLink5") }}</a></li>
            </ul>
            <div class="line m-[30rem]"></div>
            <div class="marking flex flex-col items-center">
                <div class="mb-[15rem]"> 2024 HashMelon. All right reserved.</div>
                <div class="flex gap-[30rem]">
                    <span class="cursor-pointer hover:opacity-80">Privacy Policy</span>
                    <span class="cursor-pointer hover:opacity-80">Terms of Service</span>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="less" scoped>
.footer {
    color: white;
    min-height: 694px;
    background: #0A081F;
    font-size: 16rem;
    padding: 0 20rem;

    &.mobile {
        min-height: auto;
        padding-bottom: 30rem;

        .footer-box {
            width: 100%;
        }

        ul {
            li {
                margin: 15rem 0;
            }
        }

        .desc {
            font-size: 14rem;
        }

        .marking {
            font-size: 12rem;
            text-align: center;
        }
    }

    .footer-box {
        margin: 0 auto;
        max-width: 1200rem;
    }

    ul {
        li {
            cursor: pointer;
            margin: 0 35rem;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .marking {
        color: #666666;
        font-size: 14rem;

        span {
            text-decoration: underline;
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .line {
        height: 1rem;
        border: 1rem solid #333;
        width: 100%
    }

    .iconList {
        img {
            width: 21rem;
            height: 20rem;
            filter: brightness(.5);
            transition: filter 0.3s;

            &:hover {
                filter: grayscale(1);
            }
        }
    }
}
</style>
