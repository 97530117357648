<script setup>
import { reactive } from 'vue'

const formInline = reactive({
    user: '',
    region: '',
    date: '',
})

const onSubmit = () => {
    console.log('submit!')
}
const rules = reactive({
    name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
        { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
    ],
})
</script>

<template>
    <div class="BankManage">
        <div class="banks">
            <ul class="justify-between">
                <li
                    class="flex items-center justify-between px-[30rem] pb-[30rem] py-[40rem] cursor-pointer sm:!w-[100%] xs:!w-[100%]">
                    <div>
                        <img src="@/assets/img/bank/cmb.png" alt="">
                    </div>
                    <span>5508 **** **** 7652</span>
                </li>
                <li
                    class="flex items-center justify-between px-[30rem] py-[40rem] relative cursor-pointer sm:!w-[100%] xs:!w-[100%]">
                    <div>
                        <img src="@/assets/img/bank/chase.png" alt="">
                    </div>
                    <span>5508 **** **** 7652</span>
                    <span class="position-absolute setBtn">Set as Default</span>
                </li>
                <li
                    class="flex items-center justify-between px-[30rem] py-[40rem] newBank cursor-pointer sm:!w-[100%] xs:!w-[100%]">
                    <div>
                        <img class="!w-[30rem]" src="@/assets/img/bank/icon.png" alt="">
                    </div>
                    <span class="ml-[30rem]">NEW Credit / Debit Card</span>
                </li>
            </ul>
        </div>
        <div class="addBank bg-white px-[30rem] py-[50rem] relative">
            <div class="position-absolute arrow"></div>
            <div class="title font-weight">Supports the following brands</div>
            <div class="iconList py-[30rem]">
                <img src="@/assets/img/bank/visa.png" alt="">
                <img src="@/assets/img/bank/mast.png" alt="">
                <img src="@/assets/img/bank/paypal.png" alt="">
            </div>
            <div class="form mt-[10rem]">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" label-position="top"
                    :rules="rules">
                    <div class="w-[100%]">
                        <el-form-item label="Card number" prop="name">
                            <el-input v-model="formInline.user" placeholder="Approved by" clearable size="large" />
                        </el-form-item>
                    </div>
                    <col>
                    <el-row class="w-[100%]">
                        <el-col :span="12">
                            <el-form-item label="First Name" prop="name">
                                <el-input v-model="formInline.user" placeholder="Approved by" clearable size="large" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Last Name" prop="name">
                                <el-input v-model="formInline.user" placeholder="Approved by" clearable size="large" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="w-[100%]">
                        <el-col :span="12">
                            <el-form-item label="Expiration Date(MM/YY)" prop="name">
                                <el-input v-model="formInline.user" placeholder="Approved by" clearable size="large" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="CVV" prop="name">
                                <el-input v-model="formInline.user" placeholder="Approved by" clearable size="large"
                                    suffix-icon="Postcard" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="btn">
                    SAVE THIS CARD
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.BankManage {
    .arrow {
        top: -40rem;
        left: calc(476rem / 2 - 20rem);
        border-color: transparent transparent white transparent;
        border-width: 20rem;
        border-style: solid;
    }

}

.banks {
    ul {
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;


        .newBank {
            background: #F5F7FC;
            border: 2rem solid #435DC8;
        }

        li {
            width: 476rem;
            height: 110rem;
            background: #FFFFFF;
            margin-bottom: 30rem;

            .setBtn {
                cursor: pointer;
                top: 13rem;
                right: 30rem;
                font-weight: 400;
                font-size: 10rem;
                color: #435DC8;
                text-decoration: underline;
            }

            &:first-child {
                background: url("../../assets/img/bank/default.png") no-repeat #FFFFFF top right;
            }

            &:last-child {
                background: url("../../assets/img/bank/arrow.png") no-repeat #F5F7FC bottom right;
            }

            img {
                width: 125rem;
                height: 30rem;
                object-fit: contain;
            }
        }
    }
}

.addBank {
    .iconList {
        display: flex;
        align-items: center;

        img {
            width: 190rem;
            height: 30rem;
            object-fit: contain;
        }
    }

    .btn {
        width: 448rem;
        height: 50rem;
        line-height: 50rem;
        text-align: center;
        margin: 20rem auto 0;
        color: white;
        background: #435DC8;
    }
}
</style>