import { isMobileDevice } from "./device-config"
export const setHtmlFontSize = () => {
    if (isMobileDevice()) {
        const htmlDom = document.getElementsByTagName('html')[0];
        let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
        htmlDom.style.fontSize = `${htmlWidth / 375}px`;
    } else {
        const htmlDom = document.getElementsByTagName('html')[0];
        let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;

        htmlWidth = htmlWidth > 1920 ? 1920 : htmlWidth;
        htmlWidth = htmlWidth < 1440 ? 1440 : htmlWidth;

        const designWidth = 1920;
        // htmlDom.style.fontSize = windowScale > designScale ? `${width / design}px` : `${htmlWidth / designWidth}px`;
        htmlDom.style.fontSize = `${htmlWidth / designWidth}px`;
    }
};
