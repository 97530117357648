<template>
    <div class="sticky bg-[#FFFFFF] top-0 z-[10] border-b-[1rem]">
        <div class="header py-[15rem] inner sticky top-0">
            <div class="header-left flex items-center">
                <router-link to="/home" class="logo cursor-pointer">
                    <img src="@/assets/logo.png" alt="" class="w-[38rem] h-[50rem] object-contain">
                </router-link>
                <nav class="ml-[100rem] xs:ml-[30rem] xs:hidden">
                    <ul class="flex items-center">
                        <li>
                            <el-dropdown :hide-on-click="false">
                                <span class="el-dropdown-link">
                                    {{ $t("nav.service") }}<el-icon class="el-icon--right">
                                        <img src="@/assets/img/arrow.png" class="mr-[10rem]" alt=""></el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item>
                                            <router-link to="/faas" class="w-full block">{{ $t("nav.faas") }}</router-link>
                                        </el-dropdown-item>
                                        <el-dropdown-item>{{ $t("nav.dappDevelopment") }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </li>
                        <li class="ml-[30rem]">
                            <el-dropdown :hide-on-click="false">
                                <span class="el-dropdown-link">
                                    {{ $t("nav.product") }}<el-icon class="el-icon--right">
                                        <img src="@/assets/img/arrow.png" class="mr-[10rem]" alt=""></el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item>
                                            <router-link to="/rwa" class="w-full block">
                                                <div class="flex items-center justify-between">
                                                    <span>{{ $t("nav.rwaplatform") }}</span>
                                                    <img src="@/assets/img/live.svg" class="ml-[16rem] w-[40rem] h-[40rem]" alt="live">
                                                </div>
                                            </router-link>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div class="flex items-center justify-between w-full">
                                                <span>{{ $t("nav.dapps") }}</span>
                                                <img src="@/assets/img/live.svg" class="ml-[16rem] w-[40rem] h-[40rem]" alt="live">
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div class="flex items-center justify-between w-full">
                                                <span>{{ $t("nav.otherProducts") }}</span>
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </li>
                        <li style="margin-left: 30rem">
                            <a href="mailto:info@hashmelon.com" class="hover:text-[#007bff]">{{ $t("nav.contact") }}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header-right flex items-center">
                <div class="language flex items-center xs:hidden">
                    <img src="@/assets/img/location.png" class="mr-[10rem]" alt="">
                    <ul class="flex items-center relative">
                        <!--active-->
                        <li v-for="lang in langs" :key="lang.key" @click.stop="changeLanguage(lang)"
                            :class="{ active: currentLang.Lang() === lang.key }">
                            {{ lang.title }}
                        </li>
                    </ul>
                </div>
                <div class="btn xs:hidden" @click="router.push('/login')">
                    {{ $t("nav.getStart") }}
                </div>
                <div class="hidden xs:block">
                    <img src="@/assets/img/app.svg" class="w-[36rem] h-[36rem] cursor-pointer" alt="menu" @click="showMobileMenu = true">
                </div>
            </div>
        </div>

        <!-- 移动端抽屉菜单 -->
        <el-drawer
            v-model="showMobileMenu"
            direction="rtl"
            size="80%"
            :with-header="false"
            class="mobile-drawer"
        >
            <div class="mobile-menu">
                <!-- Drawer Header -->
                <div class="mobile-menu-header">
                    <img src="@/assets/logo.png" alt="" class="w-[38rem] h-[50rem] object-contain">
                    <button 
                        @click="showMobileMenu = false"
                        class="mobile-close-btn"
                    >
                        <el-icon class="text-[24rem]"><Close /></el-icon>
                    </button>
                </div>

                <div class="mobile-menu-content">
                    <el-collapse v-model="activeCollapse">
                        <el-collapse-item :title="$t('nav.service')" name="1">
                            <div class="mobile-menu-item">
                                <router-link to="/faas" class="block py-2">
                                    {{ $t("nav.faas") }}
                                </router-link>
                                <router-link to="/dapp" class="block py-2">
                                    {{ $t("nav.dappDevelopment") }}
                                </router-link>
                            </div>
                        </el-collapse-item>

                        <el-collapse-item :title="$t('nav.product')" name="2">
                            <div class="mobile-menu-item">
                                <router-link to="/rwa">
                                    <div class="flex items-center justify-between">
                                        <span>{{ $t("nav.rwaplatform") }}</span>
                                        <img src="@/assets/img/live.svg" class="live-icon" alt="live">
                                    </div>
                                </router-link>
                                <router-link to="/products/2">
                                    <div class="flex items-center justify-between">
                                        <span>{{ $t("nav.dapps") }}</span>
                                        <img src="@/assets/img/live.svg" class="live-icon" alt="live">
                                    </div>
                                </router-link>
                                <router-link to="/products/other">
                                    {{ $t("nav.otherProducts") }}
                                </router-link>
                            </div>
                        </el-collapse-item>
                    </el-collapse>

                    <div class="mobile-menu-footer">
                        <a href="mailto:info@hashmelon.com">{{ $t("nav.contact") }}</a>
                        <div class="mobile-language">
                            <el-dropdown @command="changeLanguage" trigger="click">
                                <span class="mobile-dropdown-link">
                                    <img src="@/assets/img/location.png" class="mr-[10rem]" alt="">
                                    {{ currentLang.Lang() }}
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item 
                                            v-for="lang in langs" 
                                            :key="lang.key" 
                                            :command="lang"
                                        >
                                            {{ lang.title }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="mobile-btn" @click="router.push('/login')">
                        {{ $t("nav.getStart") }}
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
/** 导入已有的语言列表 */
import { currentLang, langs, setI18nLanguage } from '@/locales'
import { defineOptions, ref } from 'vue'
import router from "@/router"
import { Operation, Menu, Close } from '@element-plus/icons-vue'

defineOptions({
    name: 'Language'
})

// 移动端菜单状态
const showMobileMenu = ref(false)
const activeCollapse = ref(['1'])

/** 切换当前语言环境 */
const changeLanguage = (locale) => {
    setI18nLanguage(locale.key)
}

const handleCommand = (v) => {
    console.log(v)
}
</script>

<style lang="less" scoped>
/* Desktop layout */
.header {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.header-left {
    //display: flex; //align-items: center;

    .logo {
        width: 38rem;
        height: 50rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border: none;
        }
    }

    nav {
        font-size: 18rem;
        font-family: Inter, serif;
        font-weight: normal;
        color: #000000;
        line-height: 18rem;
    }
}

.header-right {
    margin-left: auto;

    .language {
        margin-right: 50rem;
        font-size: 18rem;

        li {
            cursor: pointer;
            //border-right: 1rem solid #ccc;
            padding: 0 5rem;
            
            &::after {
                content: ' \/';
                position: absolute;

            }

            &:last-child::after {
                content: '';
            }

            &:hover {
                color: #7188de;
            }

            &.active {
                color: #435DC8;
            }
        }
    }

    .btn {
        width: 180rem;
        height: 40rem;
        line-height: 40rem;
        color: white;
        font-size: 18rem;
        background: #435DC8;
        text-align: center;
        cursor: pointer;
    }
}

.el-dropdown-link {
    cursor: pointer;
    //color: var(--el-color-primary);
    display: flex;
    font-size: 18rem;
    align-items: center;

    &:focus {
        outline: none;
    }
}

:deep(.el-dropdown-menu__item) {
    padding: 8rem 20rem;
    
    .flex {
        min-width: 240rem;
    }
    
    img {
        display: inline-block;
        vertical-align: middle;
    }
}

/* 移动端样式 */
.mobile-drawer {
    :deep(.el-drawer__body) {
        padding: 0;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    :deep(.el-drawer) {
        height: 100vh !important;
        width: 80% !important;
    }
}

.mobile-menu {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    padding: 20rem;
    padding-bottom: calc(env(safe-area-inset-bottom) + 80rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30rem;
        position: relative;
    }

    &-content {
        flex: 1;
        overflow-y: auto;
        padding-bottom: 80rem;  /* 为底部按钮留出空间 */
    }
}

.mobile-close-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 8rem;
    border: none;
    background: transparent;
    cursor: pointer;
    
    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
}

.mobile-menu-item {
    display: flex;
    flex-direction: column;
    gap: 20rem;

    a {
        color: #000000;
        font-size: 16rem;
        text-decoration: none;
        padding-right: 20rem;

        &:hover {
            color: #435DC8;
        }
    }

    .live-icon {
        width: 40rem;
        height: 40rem;
    }
}

.mobile-menu-footer {
    margin-top: 30rem;
    display: flex;
    flex-direction: column;
    gap: 20rem;
    padding-bottom: 80rem;

    .mobile-language {
        .mobile-dropdown-link {
            display: flex;
            align-items: center;
            font-size: 16rem;
            color: #000000;
            
            img {
                width: 20rem;
                height: 20rem;
            }
        }
    }

    a {
        color: #000000;
        font-size: 16rem;
        text-decoration: none;

        &:hover {
            color: #435DC8;
        }
    }
}

.mobile-btn {
    width: calc(100% - 40rem);
    height: 40rem;
    line-height: 40rem;
    color: white;
    font-size: 18rem;
    background: #435DC8;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    bottom: max(20rem, env(safe-area-inset-bottom));
    left: 20rem;
    z-index: 2000;
}
</style>
