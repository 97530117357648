<template>
    <div class="ServicePackage">
        <template v-if="!payStatus">
            <div class="flex items-center justify-center bg-white ">
                <div class="inner py-[50rem] flex items-center justify-between">
                    <div class="left">
                        <div class="sub text-muted">Account Email</div>
                        <div class="title">Email@email.com</div>
                    </div>
                    <div class="right">
                        <ul class="packageSwitch flex items-center" @click="btnChange">
                            <li :class="{ 'active': !btnIndex }">Manage Plan</li>
                            <li :class="{ 'active': btnIndex }">Invoices</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex items-center justify-center pt-[50rem]">
                <div class="inner w-[100%] back font-weight flex items-center" @click="payStatus = false">
                    <el-icon>
                        <ArrowLeftBold />
                    </el-icon>
                    <span class="ml-[10rem]">Back to Plans</span>
                </div>
            </div>
        </template>

        <div class="flex items-center justify-center">
            <div
                class="inner py-[50rem] flex justify-between gap-[50rem] ServicePackageBox sm:flex-col-reverse xs:flex-col-reverse">
                <div class="left">
                    <template v-if="!payStatus">
                        <div class="card mb-[50rem]" v-for="(item, i) in packageList" :key="i">
                            <div class="header flex items-center justify-between">
                                <div class="title font-weight">{{ item.title }}</div>
                                <div class="sub" v-if="item.needPackage">
                                    <span class="text-muted">Need a custom solution?</span>
                                    <span class="ml-[10rem]">Get in touch</span>
                                </div>
                            </div>
                            <div class="bg-white ">
                                <ul>
                                    <li class="flex items-center border-bottom border-light-secondary px-[20rem] py-[30rem] card-item"
                                        style="border-color: #DDDDDD;box-sizing: border-box"
                                        v-for="(serveItem, sI) in item.serveList"
                                        :key="sI"
                                        :style="{ background: radioIndex[i] === sI ? '#F5F7FC' : '' }"
                                        @click="radioChange(i, sI)">
                                        <div class="flex-[1] flex items-center">
                                            <div class="radio mr-[10rem]" :class="{ 'active': radioIndex[i] === sI }">
                                                <span></span>
                                            </div>
                                            {{ serveItem.name }}
                                        </div>
                                        <div class="text-muted flex items-center" style="flex: 2">
                                            <div class="text-light-muted deleteLine"
                                                :class="{ deleteLine: radioIndex[i] === sI }">
                                                {{ serveItem.money }}/mo
                                            </div>
                                            <div class="activeCenter" v-show="radioIndex[i] === sI">
                                                <span class="text-light-muted font-weight">${{ serveItem.sale }}</span>
                                                <div class="flex items-center">
                                                    <div class="img"><img src="@/assets/logo.png" class="" alt=""></div>
                                                    <div class="plan">CURRENT PLAN</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex  items-center"><span class="mr-[10rem]">{{ serveItem.requests
                                                }}
                                                Requests / Month</span>
                                            <el-tooltip
                                                class="box-item"
                                                effect="dark"
                                                content="【 ？？？】"
                                                placement="right">
                                                <el-icon>
                                                    <QuestionFilled />
                                                </el-icon>
                                            </el-tooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                    <bank-manage v-else></bank-manage>
                </div>
                <div class="right sm:w-[100%] xs:w-[100%]">
                    <div class="header">
                        <div class="title font-weight" style="margin-bottom: 12rem">Summary</div>
                        <div class="pay-box bg-white px-[30rem] pb-[30rem]">
                            <div class="pay-title py-[30rem] border-bottom border-light-secondary font-weight">3 Plans
                            </div>
                            <div class="pay-list  border border-light-secondary">
                                <div class="p-[20rem] border-light-secondary border-bottom">
                                    <div class="pay-list-title">Dividend Service Basic Plan</div>
                                    <div class="pay-list-desc flex items-center justify-between">
                                        <span>10,000 Requests / Month</span>
                                        <span>$100.00 /mo</span>
                                    </div>
                                </div>
                                <div class="p-[20rem] border-light-secondary border-bottom">
                                    <div class="pay-list-title">KYC Service Advanced Plan</div>
                                    <div class="pay-list-desc flex items-center justify-between">
                                        <span>10,000 Requests / Month</span>
                                        <span>$100.00 /mo</span>
                                    </div>
                                </div>
                                <div class="p-[20rem] border-light-secondary border-bottom">
                                    <div class="pay-list-title">Account Abstraction Service Basic Plan</div>
                                    <div class="pay-list-desc flex items-center justify-between">
                                        <span>10,000 Requests / Month</span>
                                        <span>$100.00 /mo</span>
                                    </div>
                                </div>
                            </div>
                            <div class="discount border my-[20rem] border-dark flex items-center justify-between"
                                style="padding:16rem 20rem">
                                <span>Discount Code</span>
                                <span style="text-decoration: underline">APPLY</span>
                            </div>
                            <div class="mt-[10rem] mb-[20rem] flex items-center justify-between font-weight">
                                <div class="flex items-center">
                                    <span class="mr-[10rem]">Order Total</span>
                                    <el-tooltip
                                        class="box-item"
                                        effect="dark"
                                        placement="right">
                                        <template #content>
                                            <p class="" style="width: 238rem">
                                                Your invoice will contain the final sales tax, including state and local
                                                taxes,as well as any
                                                applicable rebates and fees.
                                            </p>
                                        </template>
                                        <el-icon>
                                            <QuestionFilled />
                                        </el-icon>
                                    </el-tooltip>
                                </div>
                                <span>$225.2 /mo</span>
                            </div>
                            <div class="mb-[20rem] flex items-center justify-between font-weight">
                                <div class="">Discount</div>
                                <span>$225.2 /mo</span>
                            </div>
                            <div class="mb-[20rem] flex items-center justify-between font-weight">
                                <div class="">Total to play</div>
                                <span>$225.2 /mo</span>
                            </div>
                            <div class="btn mt-[10rem]" @click="payStatus = true">
                                {{ payStatus ? 'Submit Order' : 'Choose Payment' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import BankManage from "@/views/MemberHome/BankManage.vue";
import { ref, reactive, onMounted } from "vue";

let payStatus = ref(true)
const radioIndex = reactive([1, 1, 1, 1])
const btnIndex = ref(false)

let packageList = reactive([
    {
        title: 'Tokenization Service',
        needPackage: true,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    },
    {
        title: 'Dividend Service(DaaS)Plans',
        needPackage: false,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    },
    {
        title: 'KYC Service(KYCaaS)P',
        needPackage: false,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    },
    {
        title: 'Account Abstraction Service(AAaaS)Plans',
        needPackage: false,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    },
    {
        title: 'Derivative Financial Service(DFaaS)Plans',
        needPackage: false,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    },
    {
        title: 'CaaS Service Plans',
        needPackage: false,
        serveList: [
            {
                name: 'No Plan',
                money: '0',
                sale: 0,
                requests: 0
            },
            {
                name: 'Basic',
                money: '50',
                sale: 0,
                requests: '10,000'
            },
            {
                name: 'Advanced',
                money: '500',
                sale: 0,
                requests: '100,000'
            },
            {
                name: 'Professional',
                money: '1000',
                sale: 0,
                requests: '1000,000'
            }
        ]
    }
])

onMounted(() => {
})

const btnChange = () => {
    btnIndex.value = !btnIndex.value
}
const radioChange = (i, sI) => {
    radioIndex[i] = sI
}
</script>

<style lang="less" scoped>
.ServicePackage {
    font-size: 18rem;
    font-family: Inter, Inter;
    min-height: 100vh;

    .back {
        font-size: 24rem;
        cursor: pointer;
    }

    .radio {
        width: 18rem;
        height: 18rem;
        border-radius: 50%;
        border: 1rem solid #b3b3b3;
        align-items: center;
        justify-content: center;
        display: flex;

        &.active {
            border: 1rem solid #435DC8;

            span {
                display: block;
            }
        }

        span {
            display: none;
            width: 9rem;
            height: 9rem;
            background: #435DC8;
            border-radius: 50%;
        }

    }

    .activeCenter {
        margin-left: 10rem;
        display: flex;
        align-items: center;

        .plan {
            width: 120rem;
            height: 32rem;
            border: 1rem solid #435DC8;
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: 12rem;
            color: #435DC8;
            text-align: center;
            line-height: 32rem;
        }

        span {
            font-size: 18rem;
        }

        .img {
            width: 27rem;
            height: 27rem;
            margin: 0 10rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .ServicePackageBox {
        .left {
            flex: 2;
            .card-item {
                height: 60rem;
                transition: all .5s;

                .deleteLine {
                    font-size: 14rem;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 50%;
                        left: 0;
                        width: 100%;
                        border-bottom: 2rem solid black;
                    }

                    //text-decoration: line-through;
                }
            }


            .header {
                margin-bottom: 12rem;

                .title {
                    font-family: Inter, Inter;
                    font-size: 18rem;
                }

                .sub {
                    span {
                        font-size: 16rem;

                        &:last-child {
                            font-weight: 400;
                            color: #435DC8;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .right {
            flex: 1;
            align-self: flex-start;
        }
    }

    .left {
        .title {
            font-family: Inter, Inter;
            font-weight: normal;
            font-size: 30rem;
        }
    }

    .right {
        ul {
            padding: 0;
            //background: #FFFFFF;
            border: 1rem solid #000000;

            li {
                padding: 15rem 35rem;
                cursor: pointer;
                transition: all .5s;

                &.active {
                    background: #0A081F;
                    color: white;
                }
            }
        }

        .pay-box {
            font-family: Inter, Inter;

            .pay-list {
                div:last-child {
                    border: none;
                }
            }

            .pay-list-title {
                font-size: 18rem;
                font-weight: bold;
            }

            .pay-list-desc {
                margin-top: 12rem;
                font-weight: 400;
                font-size: 16rem;
                line-height: 16rem;
            }

            .btn {
                width: 448rem;
                height: 50rem;
                background: #435DC8;
                font-family: Inter, Inter;
                font-size: 18rem;
                color: #FFFFFF;
                line-height: 50rem;
                text-align: center;

            }
        }
    }
}
</style>