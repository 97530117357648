<template>
    <div class="Stats" style="margin: 0 auto;">
        <div class="flex items-center justify-center bg-white ">
            <div class="inner py-[50rem] w-[100%] flex gap-[30rem] flex-wrap ">
                <el-select v-model="value" placeholder="Select" popper-class="stats-select" size="large"
                    style="width: 240rem">
                    <template #prefix>
                        <el-icon>
                            <Key />
                        </el-icon>
                    </template>
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="searchData" placeholder="Select" size="large"
                    style="width: 240rem;" @change="dataChange">
                    <template #prefix>
                        <el-icon>
                            <Calendar />
                        </el-icon>
                    </template>
                    <el-option v-for="item in searchDataList" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </div>
        </div>
        <div class="flex items-center justify-center">
            <div class="inner w-[100%]">
                <div class="title py-[50rem] items-center">
                    <div class="mr-1"> Requests Volume</div>
                    <el-icon>
                        <QuestionFilled />
                    </el-icon>
                </div>
                <div class="chart bg-white p-[30rem]">
                    <div class="ChartTitle flex items-center justify-between xs:flex-col">
                        <div class="title">{{ searchDataStr }} TOTAL</div>
                        <div class="btn xs:mt-[20rem]" @click="goPackage">UPGRADE PLAN</div>
                    </div>
                    <ul class="statistics flex mt-[30rem] xs:flex-col items-end xs:items-start">
                        <li>1,226,116 <span class="text-[24rem]">/ 1,000,000</span></li>
                        <li class="text-[#435DC8] text-[16rem] underline cursor-pointer" 
                            @click="goPackage">Purchase Request Pac</li>
                    </ul>
                    <div id="statsChart" class="statsChart mt-[30rem]" ref="statsChart"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import * as echarts from 'echarts';
import {useRouter} from "vue-router";
const router = useRouter();
// const route = useRoute();
/************chart start*********************/

let myChart = null;
const chartInit = () => {
    let i = 0
    let count = []
    let titleStr = []
    while (i < 23) {
        i++
        count.push(i)
        titleStr.push('titleStr')
    }
    console.log(i, 'i');
    var chartDom = document.querySelector('#statsChart');
    myChart = echarts.init(chartDom);
    // 绘制图表
    myChart.setOption({
        // title: {
        //     text: 'ECharts 入门示例'
        // },
        grid: {
            // show: true,                                 //是否显示图表背景网格    
            left: '0%',                                    //图表距离容器左侧多少距离
            right: '0%',                                //图表距离容器右侧侧多少距离
            bottom: '3%',                              //图表距离容器上面多少距离
            top: 50,                                       //图表距离容器下面多少距离
            containLabel: true,                     //防止标签溢出  
            // backgroundColor: '#555555',     //网格背景色，默认透明
        },
        legend: {
            icon:'circle'
        },
        tooltip: {},
        xAxis: {
            data: [...count]
        },
        yAxis: {},
        series: [
            {
                name: '销量',
                type: 'bar',
                data: [...count]
            }
        ]
    });
}
/************chart end*********************/

const resize = () => {
    myChart.resize()
}

const goPackage = ()=>{
    router.push('/MemberHome/ServicePackage')
}
onMounted(() => {
    chartInit()
    window.addEventListener('resize', resize)
})

onUnmounted(() => {
    window.removeEventListener('resize', resize)
})


const value = ref('')
const searchData = ref('')
const searchDataStr = ref('Last 24 Hours')

const options = [
    {
        value: '？？？',
        label: '？？？',
    },

]
const searchDataList = [
    {
        value: 'Last 24 Hours',
        label: 'Last 24 Hours',
        // label: 'Last24Hours',
    },
    {
        value: 'Last 7 Days',
        label: 'Last 7 Days',
        // label: 'Last7Days',
    },
    {
        value: 'Last 30  Days',
        label: 'Last 30  Days',
        // label: 'Last30Days',
    },
]
const dataChange = (e) => {
    searchDataStr.value = e
    console.log(e, 'e');
}
</script>

<style lang="less" scoped>
.Stats {
    font-size: 24rem;
    font-family: Inter, Inter;

    .title {
        display: flex;
        // font-family: Inter, Inter;
        font-size: 30rem;
        color: #000000;

    }

    .chart {
        .ChartTitle {
            font-weight: 400;
            font-size: 24rem;
            color: #000000;

            .btn {
                padding: 10rem 20rem;
                width: 180rem;
                height: 40rem;
                background: #435DC8;
                color: white;
                font-size: 18rem;
                line-height: normal;
            }
        }

        .statistics {
            justify-content: baseline;
            
            li {

                &:nth-child(1) {
                    font-size: 36rem;
                    color: #000000;
                }

                &:nth-child(2) {
                    margin-left: 10rem;
                    font-weight: 400;
                    font-size: 24rem;

                }

                &:nth-child(3) {
                    margin-left: 20rem;
                    font-weight: 400;
                    font-size: 16rem;
                    color: #435DC8;
                    text-decoration: underline;
                }
            }
        }

        .statsChart {
            box-sizing: border-box;
            // width: 100%;
            height: 400rem;
        }
    }
}
</style>