<template>
    <div class="login  relative">
        <img class="position-absolute w-[100%]" style="z-index: 0;object-fit: contain" src="@/assets/img/home/09.png"
            alt="">
        <div class="login-box">
            <div class="top">
                <img src="@/assets/logo.png" alt="">
                <p class="str">{{ $t("login.ResetPassword") }}</p>
            </div>
            <div class="form mt-3">
                <!--        :size="size"-->
                <el-form
                    ref="loginForm"
                    style="max-width: 600rem"
                    :model="form"
                    label-width="auto"
                    label-position="top">
                    <el-form-item :label="$t('login.Email')">
                        <el-input v-model="form.email" type="text" @input="inputChange"
                            :placeholder="$t('login.EmailAddress')" />
                    </el-form-item>
                </el-form>
                <div class="loginBtn" style="margin-top: 130rem"
                    :style="{ background: btnStyle[btnStyleIndex].bg, color: btnStyle[btnStyleIndex].color }"
                    @click="router.push('/resetUpdate')">
                    {{ $t("login.Next") }}
                </div>
                <div class="loginBtn cancelBtn" @click="router.push('/')">
                    {{ $t("login.Cancel") }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref } from 'vue'
import router from "@/router";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const form = reactive(
    { email: '' }
)
let btnStyleIndex = ref(0)
const btnStyle = reactive([{ bg: '#ECF0F3', color: '#AAAAAA' }, { bg: '#435DC8', color: 'white' }])
const inputChange = () => {
    if (form.email) {
        btnStyleIndex.value = 1
    } else {
        btnStyleIndex.value = 0
    }
}

</script>

<style lang="less" scoped>
.login {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    margin: 0 auto;
    height: 100vh;
    text-align: center;
    font-size: 24rem;
    overflow-y: hidden;

    .top {
        img {
            height: 70rem;
            object-fit: cover;
        }

        .str {
            font-weight: normal;
            font-size: 24rem;
            color: #000000;
            line-height: 24rem;
            font-style: normal;
            margin: 10rem 0;
        }

        .desc {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 14rem;
            font-style: normal;
            margin-top: 10rem;
            margin-bottom: 30rem;
        }
    }

    .login-box {
        //top:50%;
        padding: 50rem;
        position: absolute;
        width: 468rem;
        height: 520rem;
        background: #FFFFFF;
        box-shadow: 0 0 10rem 2rem #E5EFFF;
        border-radius: 6rem;
    }


    .loginBtn {
        cursor: pointer;
        width: 368rem;
        height: 40rem;
        font-size: 14rem;
        line-height: 40rem;
        font-style: normal;
        margin-top: 10rem;
    }

    .cancelBtn {
        font-size: 14rem;
        color: #333333;
        font-style: normal;
        background: #FFFFFF;
        border: 1rem solid #D9D9D9;

        &:hover {
            background: rgba(67, 93, 200, .8);
            color: #FFFFFF;
        }
    }
}
</style>
