import { createI18n } from 'vue-i18n'
// 导入语言
import en from './en'
import zh from './zh'
import hk from './hk'

let currentLanguage = null;
/** 
 * 获取浏览器界面语言，默认语言。 navigator.language: zh-CN
 * 我们只需要它的前缀：zh，所以使用replace将zh后面的替换为空
*/
export function getLanguage() {
    currentLanguage = navigator.language.replace(/-[A-Za-z]*/, '')
    /** 从本地缓存localStorage中获取语言环境 */
    const locales = localStorage.getItem('locales')
    // JSON.parse(locales)
    /** 如果本地缓存中记录了语言环境，则使用本地缓存记录的语言环境  */
    if (locales) currentLanguage = locales;
    return currentLanguage
}

/** 创建i18n实例对象 */
const i18n = createI18n({
    locale: getLanguage(),
    legacy: false, // 防止组件引入i18n，vite脚手架报错
    messages: {
        zh,
        en,
        hk
    }
})

let mainApp = null
/**
 * 安装i8n
 * @param app vue实例对象
 */
export const setupI18n = (app) => {
    mainApp = app;
    mainApp.use(i18n)
}

export const setI18nLanguage = (locales) => {
    window.localStorage.setItem('locales', locales);
    i18n.global.locale.value = locales
    mainApp.use(i18n)
}

/** Nav.vue头部导航栏中英文切换下拉菜单使用 */
export const langs = [
    { key: 'zh', title: '简' },
    { key: 'hk', title: '繁' },
    { key: 'en', title: 'EN' },
]
export const currentLang = {
    Lang: getLanguage
}
