import {createRouter, createWebHistory} from 'vue-router'
import Login from '@/views/Login'
import Stats from '@/views/MemberHome/Stats.vue'
import ServicePackage from '@/views/MemberHome/ServicePackage.vue'
import MemberHome from '@/views/MemberHome/index.vue'
import resetEmail from '@/views/Login/resetOne.vue'
import resetUpdate from '@/views/Login/resetTwo.vue'
import Layout from '@/views'

let routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/resetEmail',
        component: resetEmail,
    },
    {
        path: '/resetUpdate',
        component: resetUpdate,
    },
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/Home/index.vue')
            },
            {
                path: 'faas',
                name: 'faas',
                component: () => import('@/views/FaaS/index.vue')
            },
            {
                path: 'rwa',
                name: 'rwa',
                component: () => import('@/views/RWAPlatform/index.vue')
            }
        ]
    },
    {
        path: '/MemberHome',
        component: MemberHome,
        redirect: '/MemberHome/Stats',
        children: [
            {
                path: 'Stats',
                component: Stats
            },
            {
                path: 'ServicePackage',
                component: ServicePackage
            },
        ]
    },
    {
        path: "/:cacheAll(.*)",
        redirect: '/Layout',
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router
