<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'

export default {
    name: 'App',
    components: {
        ElConfigProvider
    },
    setup() {
        const { t, locale } = useI18n()
        
        // 监听语言变化，更新标题
        watch(() => locale.value, () => {
            document.title = t('common.siteTitle')
        }, { immediate: true })

        return {
            locale: zhCn,
        }
    },
}
</script>
