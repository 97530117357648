

export function isMobileDevice() {
    const isDesktop1 = /Windows|Macintosh|Linux/.test(navigator.userAgent);

    const isDesktop2 = /Win|Mac|Linux/.test(navigator.platform);

    const isMobile1 = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const isMobile2 = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) !== null

    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['mobile', 'android', 'iphone', 'ipad'];
    const isMobile3 = mobileKeywords.some(keyword => userAgent.indexOf(keyword) !== -1);

    const ratio = window.innerWidth / window.innerHeight;
    const mobileRatioThreshold = 0.6;

    const isMobile4 = navigator.userAgentData?.mobile ?? ratio < mobileRatioThreshold;

    return !!(!isDesktop1 || !isDesktop2 || isMobile1 || isMobile2 || isMobile3 || isMobile4);
}

// 判断是否进入全屏模式
export function checkFullscreen() {
    const isInFullscreen = document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;

    return isInFullscreen;
}

// 进入全屏模式
export function enterFullscreen() {
    let element = document.documentElement; // 获取HTML元素
    let requestFullscreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;

    if (requestFullscreen) {
        requestFullscreen.call(element);
    }
}

// 退出全屏模式
export function exitFullscreen() {
    let exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

    if (exitFullscreen) {
        exitFullscreen.call(document);
    }
}

export function onTouchMove(up,down,end) {
    let startY; // 记录起始位置的Y坐标

    if (isMobileDevice()) {
        window.addEventListener('touchstart', function (event) {
            // 记录起始位置的Y坐标
            startY = event.touches[0].clientY;
            up && up();
        });

        window.addEventListener('touchmove', function (event) {
            let currentY = event.touches[0].clientY;
            let deltaY = currentY - startY;

            // 根据 deltaY 的值判断滑动方向
            if (deltaY < 0) {
                // 向上滑动
                // up && up();
            } else if (deltaY > 0) {
                // 向下滑动
                down && down();
            }
        });
        window.addEventListener('touchend', function () {
            end && end()
        });
    }
}
