export default {
    nav:{
        service:'服務',
        contact:'聯繫我們',
        getStart:'開始',
        Stats: '統計',
        APIKeys: 'API密鑰',
        Ticket: '工單',
        Account: '賬戶',
        Billing: '賬單',
        LogOut: '退出',
        Settings: '設置',
        fractionalizeAssets: '份額化你的資產',
        dappDevelopment: 'DApp開發',
        product: '產品',
        rwaplatform: 'RWA平台',
        dapps: 'DApps',
        otherProducts: '其他區塊鏈產品',
        live: '演示'
    },
    common: {
        siteTitle: 'HashMelon - 資產通證化',
    },
    login:{
        welcome:'歡迎',
        welcomeDesc:'登入到HashMelon',
        Email:'郵箱',
        EmailAddress:'請輸入郵箱',
        Password:'密碼',
        ForgotPassword:'忘記密碼？',
        SignIn:'登入',
        ResetPassword:'重置您的密碼',
        Next:'下一步',
        Cancel:'取消',
        VerificationCode:'驗證碼',
        EnterCode:'請輸入驗證碼',
        Resend:'發送',
        NewPassword:'新的密碼',
        RepeatPassword:'再次輸入新的密碼',
        ChangePassword:'確認'
    },
    home:{
        welcome:'歡迎來到HashMelon',
        welcomeStr:'HashMelon是一家致力於提供FaaS（資產份額化服務）的公司',
        welcomeBtn:'了解更多',
        getStart:'開始使用',
        getStartStr:`HashMelon是資產份額化的最佳解決方案。
                     這項技術持續創新發展。
                     與我們聯繫可以為您提供專業幫助。
                     如果您需要對實物資產進行份額化，以下是我們為您提供的建議。`,
        cardOneLeftTile:'選擇業務類型',
        cardOneLeftStr:'我們目前支持房地產、遊艇、古董等資產的份額化服務。更多領域的服務支持敬請期待。',
        cardOneRightTile:'聯繫團隊',
        cardOneRightStr:'歡迎諮詢包括運營方式、技術對接、合規風控等問題，我們將從專業角度為您解答。',
        cardTwoLeftTile:'選擇套餐',
        cardTwoLeftStr:'根據我們的專業反饋，結合實際情況，選擇最適合您需求的服務套餐。包括份額化服務、鑄造服務、二級市場服務等。',
        cardTwoRightTile:'開始構建',
        cardTwoRightStr:'購買服務後，您可以使用我們的管理控制台開啟資產份額化之旅。',
        whatIsFaaS:'什麼是FaaS？',
        whatIsFaaSStr:'FaaS是一項可以將實物資產份額化為NFT的技術，通過這種方式將有形資產一比一對應轉移到Web3世界，現實中的每個份額都對應區塊鏈上獨一無二的NFT。我們的底層接口非常豐富，既滿足用戶大膽創新的賺錢方式，也包含許多其他創新功能。FaaS服務面向所有企業開放，無論您處於哪個行業，我們都將滿足您對資產份額化的全方位支持。',
        whatIsFaaSBtn:'聯繫我們',
        managementConsoleTitle:'便捷易用的管理控制台',
        managementConsoleStr:'我們提供企業賬戶，用戶可登錄我們功能強大的企業管理控制台。即使是沒有Web3技術知識的團隊，也能輕鬆便捷地對現有資產進行份額化，實現一站式服務。',
        web3Title:'Web3技術支持',
        web3Str:'我們攻克了眾多技術壁壘，使產品與Web3網絡上的NFT完美對接，確保所有信息透明、可追溯且不可篡改。這為用戶在現實世界中的權益提供了保障。',
        PRICING: '最佳定價',
        plan1: '沒有隱藏的費用',
        plan2: '選擇您的計畫。',
        FreeTrial: '免費試用',
        Save: '節省',
        planBtn:'購買計畫',
        Monthly: '月',
        DemoFile: '演示檔案',
        Dedicated: '10 GB專用託管免費',
        Support: '終身免費支持',
        Optimiized: 'SEO優化',
        LiveSupport: '線上支持',
        comingSoon: '即將推出...',
        footerStr: '在社交媒體上關注我們即將發佈的公告。',
        footerLink1: '團隊',
        footerLink2: '常見問題',
        footerLink3: '關於',
        footerLink4: '象徵你的房子',
        footerLink5: '聯繫我們',
    },
    faas: {
        title: 'FaaS - 碎片化即服務',
        description: '碎片化即服務（Fractionalization as a Service，即FaaS）是Hashmelon提供的合規、開放、易用的現實資產上鏈的平台。它幫助資產所有者通過零售方式將部分或全部產權份額化，增加流通性，降低交易門檻，從而快速回籠資金。',
        tokenModelTitle: '代幣模型',
        tokenModelDesc: 'FaaS通過半同質化代幣協議（ERC3525），為資產代幣化提供前所未有的靈活性與擴展性，同時確保合規性與安全性。',
        tokenModelComparison: '代幣模型對比',
        tokenType: '代幣類型',
        homogeneous: '同質化代幣',
        nonHomogeneous: '非同質化代幣',
        semiHomogeneous: '半同質化代幣',
        uniqueness: '獨特性',
        content: '內容',
        value: '價值',
        valueAndMore: '價值+圖片+其他',
        uniquenessSupport: '獨特性支持',
        liquidity: '流動性',
        metadata: '元數據',
        shareSplit: '股份分割',
        medium: '一般',
        poor: '差',
        useCasesTitle: '應用場景',
        useCasesDesc: 'FaaS適用於房地產碎片化投資、農產品產業鏈管理等多個領域，助力傳統行業向數字化轉型。',
        propertyName: '房屋名稱',
        propertyLocation: '房屋位置',
        losAngeles: '美國洛杉磯',
        propertyArea: '房屋面積',
        propertyValue: '房產價值',
        expectedReturn: '預期年化收益',
        tokenAmount: '發行代幣數量',
        minInvestment: '最低投資金額',
        serviceSupportTitle: '服務支持',
        serviceSupportDesc: 'FaaS提供多個版本的服務支持，從基礎版到專業版，為不同需求的客戶量身打造。',
        source: '來源'
    }
}
