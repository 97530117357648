import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import router from '@/router'
import {setHtmlFontSize} from "@/assets/js/reset";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

/** 导入安装i18n函数 */
import { setupI18n } from '@/locales'

// 自定义css
import '@/assets/css'
/** 创建vue实例对象 */
const app = createApp(App);
/** 安装i18n国际化语言 */
setupI18n(app)

app.use(router).use(ElementPlus).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
window.onresize = setHtmlFontSize;
setHtmlFontSize();
